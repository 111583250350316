import {gsap} from 'gsap'

const test = ()=>{
    
    const menu_button = $('.menu')
    const mobile_nav = $('.mobile_nav')
    const bar1 =  $('.menu span:nth-child(1)')
    const bar2 =  $('.menu span:nth-child(2)')
    const bar3 =  $('.menu span:nth-child(3)')
    const nav = $('nav')
    var bool = true;
    bool = !bool

    //Mobile Menu Stagger Animation

    const menu_items = $('.mobile_nav ul li')
    const social = $('.socials')
    const location = $('.location')

    menu_button.click(function(){
    nav.toggleClass('fix_nav')
    

    if(bool = !bool){
        bar1.css({
        transform: 'rotate(45deg) translate(-2px, -1px)'
        })
    
        bar3.css({
        opacity:0,
        transform: 'rotate(0deg) scale(0.2, 0.2)'
        })
    
        bar2.css({
        transform: 'rotate(-45deg) translate(-5px, 2px)'
        })

        mobile_nav.css({
            'display':'block'
        })

        setTimeout(function(){
            mobile_nav.css({
                'left':'0'
            })
        },100)

        //Menu Animation
        gsap.to(menu_items,{
            'margin-left':0,
            delay:.4,
            stagger:.1,
            alpha:1,
            duration:.5
        })

        gsap.to(social,{
            'margin-left':0,
            delay:1,
            alpha:1,
            duration:.5,
        })

        gsap.to(location,{
            'margin-left':0,
            delay:1.2,
            alpha:1,
        })

    }else{
        bar1.css({
        transform: 'rotate(0deg) translate(0px, 0px)'
        })
    
        bar3.css({
        opacity:1,
        transform: 'rotate(0deg) scale(1, 1)'
        })
    
        bar2.css({
        transform: 'rotate(0deg) translate(0px, 0px)'
        })

        

        mobile_nav.css({
            'left':'100%'
        })

        setTimeout(function(){
            mobile_nav.css({
                'display':'block'
            })
        },100)

        //Menu Animation
        gsap.to(menu_items,{
            'margin-left':'30px',
            stagger:.1,
            alpha:0
        })

        gsap.to(social,{
            'margin-left':'30px',
            alpha:0,
        })

        gsap.to(location,{
            'margin-left':'30px',
            alpha:0,    
        })
    }


    
    })

    
    


}

export {test}